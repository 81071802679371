.ui.dropdown .menu>.item.bookingscreate-dropdown-item {
  padding: 0 !important;
}

.bookings-container {
  position: inherit;
  margin: 30px 44px 12px 44px;
  padding: 20px;
  background-color: white;
  bottom: 10px;
  top: 150px;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
  transition-property: box-shadow, transform;
  color: #333;
  max-width: 2000px;
}

.bookings-extras-options-indicator {
  font-size: 9px;
  font-style: normal;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1px;
  padding-bottom: 0;
  background-color: #7268cd;
  color: white;
  font-weight: 700;
  border-radius: 2px;
  width: 12px;
  text-align: center;
  margin-bottom: 0;
}

.bookings-items-container {
  position: inherit;
  margin: 0px 44px 12px 44px;
  padding: 20px;
  background-color: white;
  bottom: 10px;
  top: 150px;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
  transition-property: box-shadow, transform;
  color: #333;
  max-width: 2000px;
}

.bookings-create-mobile-cart::-webkit-scrollbar {
  display: none;
}

.tax-scrollbar::-webkit-scrollbar {
  display: none;
}

.booking-discount-input {
  height: 30px;
  width: 40px;
  text-align: right;
  border: 1px solid lightgray;
  border-radius: 6px;
  padding-right: 4px;
}

.booking-discount-input:focus {
  outline: 1px solid #7268cd;
}

.bookings-create-discount-buttons {
  outline: 3px solid #7268cd;
  border-radius: 12px;
  padding: 5px;
  text-align: center;
  font-weight: 700;
  font-size: 17px;
  color: #7268cd;
  background-color: #7268cd12;
  width: 25%;
}

.bookings-create-discount-buttons-unselected {
  outline: 1px solid #7268cd;
  border-radius: 12px;
  padding: 5px;
  text-align: center;
  font-weight: 700;
  font-size: 17px;
  color: #7268cd;
  background-color: #7268cd12;
  width: 25%;
}

.bookings-create-loader::after {
  border-color: #7268cd #f5f5f5 #f5f5f5 !important;
}

.ui.selection.active.dropdown.change-table-dropdown .menu {
  height: 130px !important;
}

.bookings-container-grid {
  position: inherit;
  margin: 30px 44px 12px 44px;
  padding: 20px;
  background-color: white;
  height: 80px;
  color: #333;
  max-width: 2000px;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
}

.bookings-create-plus-minus {
  display: flex;
}

.bookings-container-employee {
  position: fixed;
  bottom: 20px;
  top: 136px;
  margin: 24px 12px 12px 12px;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
  transition-property: box-shadow, transform;
  color: #333;
  max-width: 2000px;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
}

.bookings-container-tax {
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
  transition-property: box-shadow, transform;
}

.bookings-container-table {
  position: relative;
  top: 4px;
  margin: 24px 12px 12px 12px;
  padding: 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
  transition-property: box-shadow, transform;
  color: #333;
  min-width: 1000px;
  max-width: 2000px;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
}

.bookings-container-employee-grid {
  position: inherit;
  margin: 28px 12px 12px 12px;
  padding: 20px;
  background-color: white;
  height: 80px;
  color: #333;
  max-width: 2000px;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
}

.bookings-toolbar {
  display: flex;
  float: right; 
  gap: 8px; 
  height: 30px; 
  margin-right: 60px; 
}

.bookings-items-toolbar {
  display: flex;
  float: right; 
  gap: 8px; 
  height: 30px; 
  margin-right: 55px; 
}

.bookings-toolbar-manager {
  display: flex;
  height: 40px; 
  justify-content: space-between;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 20px;
  align-items: center;
}

.ui.selection.dropdown.expenses-addition-field {
  border-radius: 5px !important;
  height: 10px !important;
}

.ui.active.visible.fluid.search.selection.dropdown.expenses-addition-field {
  border-radius: 5px !important;
}

.ui.selection.active.dropdown.expenses-addition-field .menu {
  border-radius: 5px !important;
  border-top: 1px solid #7268cd !important;
  border-bottom: 1px solid #7268cd !important;
  margin-top: 4px;
  margin-bottom: 4px;
}

.bookings-table {
  border-radius: 0px !important;
  border-width: 0px !important;
}

.bookings-table-container-discount {
  overflow-y: scroll;
  /* position: relative; */
  max-height: 270px;
  scrollbar-width: none;
  border-radius: 20px;
  border-width: 1px;
  border-color: #eee;
  border-style: solid;
}

.bookings-table-container-discount::-webkit-scrollbar {
  display: none;
}

.bookings-table-container {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 300px);
  margin-top: 20px;
  scrollbar-width: none;
  border-radius: 20px;
  border-width: 2px;
  border-color: #eee;
  border-style: solid;
}

.bookings-table-container-employee {
  overflow-y: scroll;
  height: 545px;
  /* max-height: calc(100vh - 280px); */
  margin-top: 20px;
  height: calc(100vh - 290px);
  bottom: 20px;
  margin-bottom: 200px;
  scrollbar-width: none;
  border-radius: 20px;
  border-width: 2px;
  border-color: #eee;
  border-style: solid;
}

.bookings-table-container::-webkit-scrollbar {
  display: none;
}

.bookings-grid-container::-webkit-scrollbar {
  display: none;
}

.split-booking-no-scrollbar::-webkit-scrollbar {
  display: none;
}

.bookings-table-container-employee::-webkit-scrollbar {
  display: none;
}

.bookings-merge-scrollbar::-webkit-scrollbar {
  display: none;
}

.bookings-header-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.bookings-search {
  margin-right: auto;
  margin-left: 0;
}

.bookings-pagination {
  display: table;
  margin: 0 auto;
}

.bookings-dropdown {
  margin-left: auto;
  margin-right: 0;
}

.ui.focus.search.ui.search.bookings-searchbar {
  border: 1px solid #7268cd !important;
  border-radius: 20px 20px  20px 20px  !important;
  box-shadow: none !important;
}

.ui.icon.input {
  border: none !important;
  box-shadow: none !important;
}

.ui.icon.input.focus>input, .ui.icon.input>input:focus {
  border: none !important;
  box-shadow: none !important;
}

.ui.secondary.pointing.menu {
  border-bottom: 2px solid rgba(34,36,38,.1) !important;
}

.ui.selection.active.dropdown:hover {
  border: 1px solid #7268cd !important;
  border-radius: 20px 20px  0px 0px  !important;
}

.ui.selection.dropdown:focus {
  border: 1px solid #7268cd !important;
  border-radius: 20px 20px  20px 20px  !important;
}

.ui.selection.active.dropdown {
  border: 1px solid #7268cd !important;
  border-radius: 20px 20px 20px 20px !important;
}

.ui.selection.active.dropdown .menu {
  border: 1px solid #7268cd !important;
  border-radius: 0px 0px 20px 20px !important;
  border-top: none !important;
}

.ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.ui.search .prompt {
  border-radius: 20px !important;
}

.ui.selection.dropdown {
  border-radius: 20px !important;
}

.ui.selection.dropdown .menu {
  border-radius: 0 0 20px 20px !important;
}

.bookings-dropdown-min-height .visible.transition {
  min-height: 190px !important;
}

.bookings-modal-title {
  font-weight: 600;
}

.ui.button.bookings-btn, .ui.buttons.bookings-btn .button.bookings-btn, .ui.buttons.bookings-btn .or.bookings-btn {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #000 !important;
  border: none !important;
  margin-right: -8px !important;
  margin-top: -6px !important;
  font-weight: 200 !important;
  position: absolute !important;
  float: right !important;
  right: 15px !important;
}

.ui.button.bookings-btn-edit, .ui.buttons.bookings-btn-edit .button.bookings-btn-edit, .ui.buttons.bookings-btn-edit .or.bookings-btn-edit {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #000 !important;
  border: none !important;
  margin-right: -8px !important;
  margin-top: -6px !important;
  font-weight: 200 !important;
  position: absolute !important;
  float: right !important;
  right: 60px !important;
}

.ui.button.bookings-btn-split, .ui.buttons.bookings-btn-split .button.bookings-btn-split, .ui.buttons.bookings-btn-split .or.bookings-btn-split {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #000 !important;
  border: none !important;
  margin-right: -8px !important;
  margin-top: -6px !important;
  font-weight: 200 !important;
  position: absolute !important;
  float: right !important;
  right: 105px !important;
}

.ui.button.bookings-btn-split-empl, .ui.buttons.bookings-btn-split-empl .button.bookings-btn-split-empl, .ui.buttons.bookings-btn-split-empl .or.bookings-btn-split-empl {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #000 !important;
  border: none !important;
  margin-right: -8px !important;
  margin-top: -6px !important;
  font-weight: 200 !important;
  position: absolute !important;
  float: right !important;
  right: 60px !important;
}

.ui.teal.button, .ui.teal.buttons .button {
  background-color: #7268cd !important;
}

.ui.teal.button:disabled, .ui.teal.buttons:disabled .button:disabled   {
  background-color: #7268cd !important;
}

.ui.button.bookings-btn-book-empl, .ui.buttons.bookings-btn-book-empl .button.bookings-btn-book-empl, .ui.buttons.bookings-btn-book-empl .or.bookings-btn-book-empl {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #000 !important;
  border: none !important;
  margin-right: -8px !important;
  margin-top: -6px !important;
  font-weight: 200 !important;
  position: absolute !important;
  float: right !important;
  right: 105px !important;
}

.ui.button.bookings-btn:hover, .ui.buttons.bookings-btn:hover .button.bookings-btn:hover, .ui.buttons.bookings-btn:hover .or.bookings-btn:hover {
  color: #1cb394 !important;
}

.ui.modal>.actions {
  height: 60px !important;
}

.bookings-actions {
  height: 500px !important;
}

.ui.fullscreen.modal {
  left: revert !important;
}

.ui.fullscreen.modal.transition.visible.active.bookingscreatemodal {
  left: revert !important;
  height: calc(100vh - 100px) !important;
  background: #F5F5F9 !important;
}

.bookings-create-category-wrapper {
  cursor: 'pointer';
  height: calc(100vh - 160px);
  overflow: scroll;
  overflow-x: hidden;
}

.bookings-create-category-wrapper::-webkit-scrollbar {
  display: none;
}

.bookings-create-tables-items-container::-webkit-scrollbar {
  display: none;
}

.bookings-create-items-scroll::-webkit-scrollbar {
  display: none;
}

.bookings-create-summary::-webkit-scrollbar {
  display: none;
}

.bookings-create-summary-inside-container::-webkit-scrollbar {
  display: none;
}

.bookings-create-content.scrolling.content {
  height: 95% !important;
  overflow-y: hidden;
  max-height: unset !important;
  background-color: #F5F5F9 !important;
  padding-top: 0px !important;
}

.bookings-create-content {
  height: 95% !important;
  overflow-y: hidden;
  max-height: unset !important;
  background-color: #F5F5F9 !important;
  padding-top: 0px !important;
}

.bookings-create-category-image {
  margin: 10px auto;
  width: 70px;
  height: 70px;
  border-radius: 15px;
  background-color: #dfe0e3;
  display: table;
}

.day-picker {
  border: 1px solid white !important;
  border-radius: 5px;
  background-color: #7268cd2b;
  padding: 5px;
  text-align: center;
  outline: none !important;
  font-weight: 600;
  font-size: 15px;
}

.day-picker-zbon {
  border: 1px solid white !important;
  border-radius: 5px;
  background-color: #7268cd2b;
  padding: 10px;
  text-align: center;
  outline: none !important;
  font-weight: 600;
  font-size: 15px;
  width: 130px !important;
}

.day-picker:focus {
  outline: none !important;
}

.day-picker-wrapper {
  background-color: white;
}

.tax-key {
  padding-top: 10px;
  font-size: 16px;
  font-weight: 600;
}

.tax-value {
  font-size: 15px;
  font-weight: 400;
}

@media screen and (max-width: 833px) {
  p.cockpit-subtitle {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .bookings-container {
    margin: 30px 16px 12px 16px;
    position: fixed;
    bottom: 10px;
    top: 80px
  }

  .bookings-items-container {
    margin: 30px 16px 12px 16px;
    position: fixed;
    bottom: 10px;
    top: 80px
  }

  .bookings-container-grid {
    height: 180px;
    margin: 34px 16px 12px 16px;
    bottom: 10px;
    top: 80px
  }

  .bookings-table-container {
    height:  calc(100vh - 450px);
  }

  .bookings-table-container-employee {
    height:  calc(100vh - 450px);
  }

  .bookings-container-employee {
    position: fixed;
    bottom: 0;
    top: 82px;
    margin: 30px 16px 12px 16px;
  }

  .bookings-container-employee-grid {
    height: 180px;
    margin: 36px 12px 12px 12px;
  }

  .bookings-toolbar {
    margin-right: 30px; 
    margin-top: 4px;
  }

  .bookings-items-toolbar {
    margin-right: 30px; 
    margin-top: 4px;
  }

  .bookings-toolbar-manager {
    margin-right: 30px; 
    margin-top: 4px;
  }
  
  .bookings-search {
    display: table;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .bookings-pagination {
    width: 100%;
  }

  .bookings-pagination-center {
    display: table;
    margin: 0 auto;
  }
  
  .bookings-dropdown {
    display: table;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    margin-top: 14px;
  }

  .ui.button.zbon-btn, .ui.buttons.zbon-btn .button.zbon-btn, .ui.buttons.zbon-btn .or.zbon-btn {
    color: #7268cd !important;
    background-color: transparent !important;
    font-weight: 700 !important;
    box-shadow: none !important;
    text-align: right;
    float: right;
    padding-right: 30px !important;
  }

  .ui.button.zbon-btn-manager, .ui.buttons.zbon-btn-manager .button.zbon-btn-manager, .ui.buttons.zbon-btn-manager .or.zbon-btn-manager {
    color: #7268cd !important;
    background-color: transparent !important;
    font-weight: 700 !important;
    box-shadow: none !important;
    width: 100% !important;
    text-align: right;
    padding-right: 30px !important;
    float: right;
  }
}

@media screen and (max-height: 610px) {
  .bookings-container {
    margin-bottom: 240px;
  }
  .bookings-items-container {
    margin-bottom: 240px;
  }
}

@media screen and (max-height: 830px) and (min-width: 768px){
  .bookings-container {
    height: calc(100vh - 210px);
    min-height: calc(100vh - 210px);
  }
  .bookings-items-container {
    height: calc(100vh - 210px);
    min-height: calc(100vh - 210px);
  }

  .bookings-container-employee {
    /* height: calc(100vh - 180px); */
    /* min-height: calc(100vh - 180px); */
    position: fixed;
    bottom: 0px;
  }

  .bookings-container-grid {
    height: 80px;
  }

  .bookings-container-employee-grid {
    height: 80px;
  }

  .bookings-table-container {
    height:  calc(100vh - 315px);
  }

  .bookings-table-container-employee {
    height:  calc(100vh - 285px);
  }
}

@media screen and (min-width: 768px) {
  .ui.button.zbon-btn, .ui.buttons.zbon-btn .button.zbon-btn, .ui.buttons.zbon-btn .or.zbon-btn {
    color: #7268cd !important;
    background-color: transparent !important;
    font-weight: 700 !important;
    box-shadow: none !important;
    text-align: right;
    float: right;
    padding-right: 55px !important;
  }
  .ui.button.zbon-btn-manager, .ui.buttons.zbon-btn-manager .button.zbon-btn-manager, .ui.buttons.zbon-btn-manager .or.zbon-btn-manager {
    color: #7268cd !important;
    background-color: transparent !important;
    font-weight: 700 !important;
    box-shadow: none !important;
    float: right;
    text-align: right;
    padding-right: 55px !important;
  }
}

@media screen and (max-width: 1370px) and (min-width: 768px) {
  .bookings-container {
    height: calc(100vh - 215px);
    min-height: calc(100vh - 215px);
  }
  .bookings-items-container {
    height: calc(100vh - 215px);
    min-height: calc(100vh - 215px);
  }

  .bookings-container-employee {
    /* height: calc(100vh - 220px); */
    /* min-height: calc(100vh - 240px); */
    position: fixed;
    bottom: 0px;
    margin-bottom: 20px;
  }

  .bookings-container-grid {
    height: 80px;
  }

  .bookings-container-employee-grid {
    height: 80px;
    margin: 28px 12px 12px 12px;
  }

  .bookings-table-container {
    height:  calc(100vh - 315px);
  }

  .bookings-table-container-employee {
    height:  calc(100vh - 290px);
  }
}

@media screen and (max-width: 480px) {
  .bookings-create-content-mobile {
    padding-top: 0px !important;
  }
}

.ui.table thead tr:first-child>th:last-child {
  border-radius: 0px 20px 0px 0px !important;
}


.ui.table thead tr:first-child>th:first-child {
  border-radius: 20px 0px 0px 0px !important;
}