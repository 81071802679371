.payments-container {
  margin: 35px 44px 44px 44px;
  max-width: 2000px;
}

.payments-tile-container {
  position: relative;
  background-color: white;
  height: 230px;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
  transition-property: box-shadow, transform;
  color: #333;
}

.payments-content-container {
  padding: 20px;
}

.payments-header {
  font-size: 18px;
  font-weight: 600;
  width: 83%;
}

.payments-title {
  margin-right: 20px;
}

.payments-value {
  position: absolute;
  bottom: 10px;
  font-size: 42px;
  font-weight: 600;
}

.payments-methods-text {
  float: left;
  font-weight: 500;
  font-size: 15px;
}

.payments-methods-toggle {
  float: right;
}

.payments-methods {
  margin-bottom: 40px;
}

.payments-stripe-button {
  display: table !important;
  margin: 0 auto !important;
}

@media screen and (max-width: 500px) {
  .payments-tile-container {
    height: 260px;
  }
}

@media screen and (max-width: 400px) {
  .payments-tile-container {
    height: 310px;
  }
}

@media screen and (max-width: 768px) {
  .payments-container {
    margin: 35px 8px 44px 8px;
    max-width: 2000px;
  }
}

i.icon.money.bill.alternate.outline:before {
  content: "\f3d1";
  color: #7268cd !important;
}

i.icon.credit.card.outline:before {
  content: "\f09d";
  color: #7268cd !important;
}

.ui.basic.teal.button.payments-stripe-button, .ui.basic.teal.buttons.payments-stripe-button .button.payments-stripe-button {
  border: 1px #7268cd solid !important;
  background-color: transparent !important;
}

.settings-employee-table-flex-container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
}

.settings-container-employee-divider {
  width: 2px;
  height: 150px;
  background-color: #7268cd;
  align-self: center;
}

.settings-container {
  margin: 35px 44px 44px 44px;
  max-width: 2000px;
  padding-bottom: 80px;
}

.settings-tile-container {
  position: relative;
  background-color: white;
  height: 350px;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
  transition-property: box-shadow, transform;
  color: #333;
}

.settings-texts {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.settings-terminal-rader-table-body::-webkit-scrollbar {
  display: none;
}

.opening-hours-scrollbar::-webkit-scrollbar {
  display: none;
}

.settings-tile-container-big {
  position: relative;
  background-color: white;
  height: 860px;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
  transition-property: box-shadow, transform;
  color: #333;
}

.settings-tile-container-max {
  position: relative;
  background-color: white;
  height: 420px;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
  transition-property: box-shadow, transform;
  color: #333;
}

.settings-tile-container-large {
  position: relative;
  background-color: white;
  height: 860px;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
  transition-property: box-shadow, transform;
  color: #333;
}

.settings-tile-container-medium {
  position: relative;
  background-color: white;
  height: 450px;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
  transition-property: box-shadow, transform;
  color: #333;
}

.settings-tile-container-printer {
  position: relative;
  background-color: white;
  height: 415px;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
  transition-property: box-shadow, transform;
  color: #333;
}

.settings-content-container {
  padding: 20px;
}

.settings-header {
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 25px;
}

.settings-title {
  margin-right: 20px;
  /* width: 20px; */
  float: left;
}

.ui.button.settings-btn, .ui.buttons.settings-btn .button.settings-btn, .ui.buttons.settings-btn .or.settings-btn {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #7268cd !important;
  border: none !important;
  margin-right: -12px !important;
  float: right !important;
  font-weight: 700 !important;
  margin-top: -5px !important;
  height: 30px !important;
  right: 0 !important;
}

.ui.button.settings-btn:hover, .ui.buttons.settings-btn:hover .button.settings-btn:hover, .ui.buttons.settings-btn:hover .or.settings-btn:hover {
  color: #7268cd !important;
}

.ui.button.settings-btn-cat, .ui.buttons.settings-btn-cat .button.settings-btn-cat, .ui.buttons.settings-btn-cat .or.settings-btn-cat {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #7268cd !important;
  border: none !important;
  margin-right: 0px !important;
  float: left !important;
  margin-left: -20px !important;
  font-weight: 700 !important;
  margin-top: -5px !important;
  height: 30px !important;
}

.ui.button.settings-btn-cat1, .ui.buttons.settings-btn-cat1 .button.settings-btn-cat1, .ui.buttons.settings-btn-cat1 .or.settings-btn-cat1 {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
  margin-right: 0px !important;
  float: left !important;
  font-weight: 700 !important;
  margin-top: -5px !important;
  height: 30px !important;
}

.settings-image {
  height: 100px;
  max-width: 100%;
  border: 1px solid transparent;
  border-radius: 6px;
  margin-bottom: 16px;
}

.settings-qrcode {
  height: 100px;
  width: 100px;
  margin-bottom: 16px;
}

.settings-table-container {
  overflow-y: scroll;
  height: 280px;
  margin-top: 20px;
  scrollbar-width: none;
  border-radius: 20px;
  border-width: 2px;
  border-color: #eee;
  border-style: solid;
}

.settings-table-container::-webkit-scrollbar {
  display: none;
}

.settings-table {
  border-radius: 0px !important;
  border-width: 0px !important;
}

.time-picker * {
  margin-bottom: 10px;
}

@media screen and (max-height: 610px) {
  .settings-container {
    margin-bottom: 240px;
  }
}

.ui.image.general-modal-image img, .ui.image.general-modal-image svg { 
  height: 100% !important;
  max-width: 95% !important;
  object-fit: contain !important;
}

@media screen and (max-width: 768px) {
  .settings-tile-container-max {
    height: 430px;
  }

  .ui.image.general-modal-image img, .ui.image.general-modal-image svg { 
    height: 120px!important;
    max-width: 95% !important;
    object-fit: contain !important;
    display: table !important;
    margin: 0 auto !important;
  }

  .settings-container {
    margin: 35px 8px 44px 8px;
    max-width: 2000px;
    padding-bottom: 100px;
  }

  .settings-employee-table-flex-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
  }

  .settings-container-employee-divider {
    display: none;
  }
}

@media screen and (max-width: 440px) {
  .settings-table-container {
    overflow-y: scroll;
    height: 280px;
    margin-top: 20px;
    scrollbar-width: none;
    border-radius: 20px;
    border-width: 2px;
    border-color: #eee;
    border-style: solid;
  }
  .settings-tile-container-max {
    height: 430px;
  }

  .settings-tile-container-printer {
    height: 450px;
  }

  .settings-tile-container-medium {
    height: 480px;
  }

  .settings-printer-mobile-breakpoint {
    flex-wrap: wrap;
  }
}

.general-modal-textarea {
  min-height: 30px;
  max-height: 82px;
  max-width: 680px;
  border-color: #ddd;
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  padding-left: 15px;
  font-family: 'Montserrat', sans-serif;
  resize: none;
}

.general-modal-textarea::placeholder {
  color: #bbb;
}

.general-modal-textarea:focus {
    outline-color: none !important;
    border: 1px solid #7268cd !important;
    outline-width: 0 !important;
    outline: none !important;
}

.picker {
  box-shadow: none !important;
  max-width: 408px !important;
  border-radius: 10px !important;
  border: 1px solid lightgray;
  overflow: hidden;
}

input[type=time] {
  border: none !important;
  color: #666;
  font-size: 16px;
  font-weight: 700;
  width: 102px;
}

input[type=time]:focus {
  outline: none !important;
}

input[type=time]::-webkit-datetime-edit-fields-wrapper {
  display: flex;
}

input[type=time]::-webkit-datetime-edit-text {
  padding: 19px 4px;
}

input[type=time]::-webkit-datetime-edit-hour-field {
  background-color: #f2f4f5;
  border-radius: 15%;
  padding: 19px 13px;
  height: 10px;
  width: 180px !important;
}

input[type=time]::-webkit-datetime-edit-minute-field {
  background-color: #f2f4f5;
  border-radius: 15%;
  padding: 19px 13px;
  height: 10px;
  width: 180px !important;
}

input[type=time]::-webkit-clear-button {
  display: none;
}

input[type=time]::-webkit-inner-spin-button {
  display: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

input::-webkit-datetime-edit-hour-field:focus,
input::-webkit-datetime-edit-minute-field:focus {
    background-color: #ddd;
    color: #333;
    outline: none;
}

.ui.modal>:first-child:not(.icon) {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
.ui.modal>:last-child {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.employee-input {
  width: 95%;
  margin-bottom: 5px;
  margin-top: 5px;
}

.search-bookings-create-input {
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
  border-radius: 20px !important;
}

.employee-dropdown {
  width: 95%;
  margin-bottom: 5px;
}

.ui.selection.dropdown.employee-dropdown {
  border-radius: 5px !important;
}

.ui.selection.dropdown.employee-dropdown .menu.employee-dropdown {
  border-radius: 5px !important;
}

.prompt.employee-dropdown {
  border-radius: 5px !important;
}

.ui.selection.active.dropdown.employee-dropdown .menu.employee-dropdown {
  border-color: #7268cd !important;
  border-radius: 0 0 5px 5px !important;
}

.ui.button.settings-close, .ui.buttons.settings-close .button.settings-close, .ui.buttons.settings-close .or.settings-close {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #000 !important;
  border: none !important;
  margin-right: -8px !important;
  margin-top: -6px !important;
  font-weight: 200 !important;
  position: absolute !important;
  float: right !important;
  right: 15px !important;
}

@media screen and (max-width: 925px) {
  input[type=time] {
    border: none !important;
    color: #666;
    font-size: 13px;
    font-weight: 600;
    width: 74px;
  }
  
  input[type=time]:focus {
    outline: none !important;
  }
  
  input[type=time]::-webkit-datetime-edit-fields-wrapper {
    display: flex;
  }
  
  input[type=time]::-webkit-datetime-edit-text {
    padding: 19px 2px;
  }
  
  input[type=time]::-webkit-datetime-edit-hour-field {
    background-color: #f2f4f5;
    border-radius: 15%;
    padding: 19px 9px;
    height: 10px;
    width: 15px !important;
  }
  
  input[type=time]::-webkit-datetime-edit-minute-field {
    background-color: #f2f4f5;
    border-radius: 15%;
    padding: 19px 9px;
    height: 10px;
    width: 15px !important;
  }
  
  input[type=time]::-webkit-clear-button {
    display: none;
  }
  
  input[type=time]::-webkit-inner-spin-button {
    display: none;
  }
  
  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
  }
  
  input::-webkit-datetime-edit-hour-field:focus,
  input::-webkit-datetime-edit-minute-field:focus {
      background-color: #ddd;
      color: #333;
      outline: none;
  }
}

.printer-input {
  width: 100%;  
  margin-bottom: 10px;
  margin-top: -10px;
}

.ui.input.printer-input>input:focus {
  border: 1px solid #7268cd !important;
}

.ui.teal.button, .ui.teal.buttons .button {
  background-color: #7268cd !important;
}

.ui.teal.button:disabled, .ui.teal.buttons:disabled .button:disabled   {
  background-color: #7268cd !important;
}

.ui.input.search-bookings-create-input>input:focus {
  border: 1px solid #7268cd !important;
  border-radius: 10px;
}

.ui.input.search-bookings-create-input>input {
  border-radius: 10px;
}

.ui.input.employee-input>input:focus {
  border: 1px solid #7268cd !important;
}

.ui.input>input:focus {
  border: 1px solid #7268cd !important;
}

.ui.focus.search.ui.search.settings-searchbar {
  border: 1px solid #7268cd !important;
  border-radius: 20px 20px  20px 20px  !important;
  box-shadow: none !important;
}

.ui.focus.search.settings-searchbar {
  border: 1px solid #7268cd !important;
  border-radius: 20px 20px  20px 20px  !important;
  box-shadow: none !important;
  width: 205px !important;
}