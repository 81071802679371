.dashboard-container {
  margin: 35px 44px 44px 44px;
  max-width: 2000px;
}

@media screen and (max-width: 768px) {
  .dashboard-container {
    margin: 35px 8px 44px 8px;
    max-width: 2000px;
    padding-bottom: 100px;
  }
}