.linechart-tile-container {
  position: relative;
  background-color: white;
  height: 520px;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
  transition-property: box-shadow, transform;
  color: #333;
}

.linechart-tile-content-container {
  padding: 20px;
}

.linechart-tile-header {
  font-size: 19px;
  font-weight: 600;
}

.linechart-tile-title {
  margin-right: 20px;
}

.linechart-tile-image {
  height: 25px;
  width: 25px;
}

.linechart-tile-image-container {
  position: absolute;
  right: 20px;
  top: 15px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: grid;
  place-items: center; 
  margin-left: 20px;
}

.linechart-tile-line {
  position: relative;
  height: 100%;
  bottom: -50px;
}

.linechart-legend {
  width: 100%;
  overflow: hidden;
  margin-bottom: -30px;
}

.linechart-legend-left {
  float: left;
  margin-right: 30px;
  color: #666;
}

.linechart-legend-right {
  overflow: hidden;
  color: #aaa;
}

.linechart-legend-title {
  font-weight: 700;
  font-size: 14px;
}

.linechart-legend-value-left {
  margin-top: -18px;
  font-weight: 500;
  font-size: 32px;
  color:rgba(244, 144, 128, 1);
}

.linechart-currency-left {
  font-size: 15px;
  vertical-align: text-bottom;
}

.linechart-currency-right {
  font-size: 15px;
  vertical-align: text-bottom;
}

.linechart-legend-value-right {
  margin-top: -18px;
  font-weight: 500;
  font-size: 32px;
}