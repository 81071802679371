.tables-container {
  position: inherit;
  margin: 35px 44px 12px 44px;
  padding: 20px;
  background-color: white;
  height: 650px;
  min-height: 650px;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
  transition-property: box-shadow, transform;
  color: #333;
  max-width: 2000px;
}

.tables-table {
  border-radius: 0px !important;
  border-width: 0px !important;
}

.tables-table-container {
  overflow-y: scroll;
  height: 545px;
  margin-top: 20px;
  scrollbar-width: none;
  border-radius: 20px;
  border-width: 2px;
  border-color: #eee;
  border-style: solid;
}

.tables-table-container::-webkit-scrollbar {
  display: none;
}

.tables-header-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.tables-search {
  margin-right: auto;
  margin-left: 0;
}

.tables-pagination {
  display: table;
  margin: 0 auto;
}

.tables-dropdown {
  margin-left: auto;
  margin-right: 0;
}

.tables-grid-item {
  background-color: #7268cd;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  text-align: center;
  font-size: 20px;
  color: white;
  font-weight: 600;
  vertical-align: middle;
  padding-top: 18px;
}

@media screen and (max-width: 767px) {
  .tables-search {
    display: table;
    margin: 0 auto;
  }
  
  .tables-dropdown {
    display: table;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: -10px;
  }

  .tables-container {
    height: 740px;
  }

  .tables-table-container {
    height: 600px;
  }
}

.ui.icon.input {
  border: none !important;
  box-shadow: none !important;
}

.ui.icon.input.focus>input, .ui.icon.input>input:focus {
  border: none !important;
  box-shadow: none !important;
}

.ui.focus.search.ui.search.tables-searchbar {
  border: 1px solid #7268cd !important;
  border-radius: 20px 20px  20px 20px  !important;
  box-shadow: none !important;
}

.ui.secondary.pointing.menu .active.item {
  border-color: #7268cd !important;
}

.ui.secondary.pointing.menu {
  border-bottom: 2px solid rgba(34,36,38,.1) !important;
} 

.ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.ui.search .prompt {
  border-radius: 20px !important;
}

.tables-modal-title {
  font-weight: 600;
}

.ui.button.tables-btn, .ui.buttons.tables-btn .button.tables-btn, .ui.buttons.tables-btn .or.tables-btn {
  color: #7268cd !important;
  background-color: transparent !important;
  margin-right: -12px !important;
  font-weight: 700 !important;
}

.ui.button.tables-close-btn, .ui.buttons.tables-close-btn .button.tables-close-btn, .ui.buttons.tables-close-btn .or.tables-close-btn {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #000 !important;
  border: none !important;
  margin-right: -8px !important;
  margin-top: -20px !important;
  font-weight: 200 !important;
  position: absolute !important;
  float: right !important;
  right: 5px !important;
}

.ui.basic.black.button.tables-close-btn {
  box-shadow: none !important;
}

.ui.basic.black.button.tables-close-btn:hover {
  box-shadow: none !important;
}

.ui.basic.black.button.tables-close-btn:active {
  box-shadow: none !important;
}

.ui.basic.black.button.tables-close-btn:focus {
  box-shadow: none !important;
}

.ui.button.tables-btn:hover, .ui.buttons.tables-btn:hover .button.tables-btn:hover, .ui.buttons.tables-btn:hover .or.tables-btn:hover {
  color: #7268cd !important;
  background-color: transparent !important;
  margin-right: -12px !important;
  font-weight: 700 !important;
}

@media screen and (max-height: 610px) {
  .tables-container {
    margin-bottom: 240px;
  }
}

@media screen and (max-width: 767px) {
  .tables-container {
    height: calc(100vh - 260px);
    min-height: calc(100vh - 260px);
    margin: 35px 16px 12px 16px;
  }

  .tables-table-container {
    height:  calc(100vh - 410px);
  }
}

@media screen and (max-height: 830px) and (min-width: 768px){
  .tables-container {
    height: calc(100vh - 230px);
    min-height: calc(100vh - 230px);
  }

  .tables-table-container {
    height:  calc(100vh - 315px);
  }
}