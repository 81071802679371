.doughnut-chart-container {
  position: relative;
  background-color: white;
  height: 450px;
  border-radius: 20px;
  box-shadow: rgb(114 104 205 / 20%) 0px 3px 7px;
  transition-property: box-shadow, transform;
  color: #333;
  padding: 20px;
}

.doughnut-content-container {
}

.doughnut-chart-header {
  font-size: 19px;
  font-weight: 600;
}

.doughnut-chart-title {
  margin-right: 20px;
}

.doughnut-chart-image-container {
  position: absolute;
  right: 20px;
  top: 15px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  margin-left: 20px;
}

.doughnut-chart-image {
  height: 25px;
  width: 25px;
}

.doughnut-chart-legend-title {
  margin-right: 30px;
  color: #666;
  font-weight: 700;
  font-size: 14px;
}

.doughnut-chart-legend-value {
  float: left;
  margin-right: 30px;
  color: #666;
  margin-top: -16px;
  font-weight: 500;
  font-size: 32px;
  color: #97caef;
  margin-bottom: 30px;
}

.doughnut-chart-bar {
  margin-top: 30px;
}

.chartInner {
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 34%;
  right: 18%;
  bottom: 10%;
  left: 18%;
  border-radius: 50%;
  padding: 1.25em 0;
  line-height: 120%;
  overflow: hidden;
}

.chartPercentage {
  font-weight: 500;
  display: table;
  margin: 0 auto;
  padding-top: 20px;
  font-size: 65px;
  padding-bottom: 35px;
}

.chartTitle {
  font-weight: 500;
  display: table;
  margin: 0 auto;
  font-size: 28px;
}

.doughnut-legend {
  width: 100%;
  overflow: hidden;
  margin-bottom: -30px;
}

.doughnut-legend-left {
  float: left;
  margin-right: 30px;
  color: #666;
}

.doughnut-legend-right {
  overflow: hidden;
  color: #666;
}

.doughnut-legend-title {
  font-weight: 700;
  font-size: 14px;
}

.doughnut-legend-value-left {
  margin-top: -18px;
  font-weight: 500;
  font-size: 32px;
  color: #97caef;
}

.doughnut-currency-left {
  font-size: 15px;
  vertical-align: text-bottom;
}

.doughnut-currency-right {
  font-size: 15px;
  vertical-align: text-bottom;
}

.doughnut-legend-value-right {
  margin-top: -18px;
  font-weight: 500;
  font-size: 32px;
  color: #97caef;
}
