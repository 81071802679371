.navbar {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 1009;
}

.navbar-customer {
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  top: 0;
  z-index: 1009;
}

.navbar-cockpit {
  padding-left: 94px;
  background: #f5f5f9;
  height: 64px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 1009;
}

.nav-menu.active {
  background: white;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
}

.nav-menu-customer.active {
  background: white;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
}

.navbar-logo-customer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-cockpit-employee {
  padding-left: 0px;
  background: #f5f5f9;
  height: 64px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 1009;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  max-width: 100vw;
}

.navbar-cockpit-login {
  padding-left: 28px;
  background: #f5f5f9;
  height: 64px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 1009;
}

.navbar-container-login {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 64px;
}


.navbar-logo:hover {
  color: #fff;
}

.logo-font {
  font-weight: 700;
  font-style: italic;
  color: #7268cd;
  height: 50px;
  width: 105px;
  margin-top: 30px;
}

.logo-font-customer {
  font-weight: 600;
  font-size: 21px;
  color: white;
  width: 70vw;
  height: 64px;
  text-overflow: ellipsis;
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-logo-cockpit {
  color: #7268cd;
  justify-self: start;
  margin-left: 24px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  width: 380px;
  align-items: center;
}

.navbar-logo-cockpit-employee {
  color: #7268cd;
  justify-self: start;
  margin-left: 24px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  width: 380px;
  align-items: center;
}

.navbar-logo-cockpit-login {
  justify-self: start;
  margin-left: 0px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  width: 380px;
  align-items: center;
}

.navbar-logo-cockpit:hover {
  color: #7268cd;
}

.navbar-logo-cockpit-employee:hover {
  color: #7268cd;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 64px);
  position: absolute;
  top: 64px;
  left: -100%;
  opacity: 1;
  transition: all 0.5s ease;
}

.nav-menu-customer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 64px);
  position: absolute;
  top: 64px;
  left: -100%;
  opacity: 1;
  transition: all 0.5s ease;
}

.nav-menu-cockpit {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  color: black;
  width: 100vw;
  justify-content: end;
  margin-right: 0rem;
}

.nav-menu-cockpit-manager {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  color: black;
  width: 100vw;
  justify-content: end;
  margin-right: 0rem;
}

.shopping-icon {
  position: absolute;
  right: 18px;
}

.nav-item {
  height: 64px;
}

.nav-links-cockpit {
  color: #777;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  
}

.nav-links-cockpit:hover {
  color: #7268cd;
}

.menu-icon {
  display:block;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-42%);
  font-size: 20px;
  cursor: pointer;
}

.menu-icon-customer {
  display:block;
  position: absolute;

  left: 12px;
  font-size: 20px;
  cursor: pointer;
}

.vertical-line {
  margin: 20px;
  border-left: 1px solid #999;
  height: 30px;
}

.nav-subtitle {
  color: #666;
  font-size: 1.8rem;
}

.nav-links {
  text-align: center;
  padding: 2rem;
  width: 100%;
  display: table;
}

.nav-links:hover {
  background-color: #fff;
  color: #242424;
  border-radius: 0;
}

.navbar-logo {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(40%, -50%);
}



@media screen and (max-width: 960px) {
  .nav-menu-cockpit {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    color: black;
    margin-right: 20px;
  }

  .nav-menu-cockpit-manager {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    color: black;
    margin-right: 20px;
  }

  .navbar-container-login {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 64px;
    width: 100%;
  }

  .nav-menu-cockpit-login {
    display: grid;
    grid-template-columns: repeat(4, auto);
    list-style: none;
    text-align: center;
    color: black;
    width: 100%;
  }

  .navbar-logo-cockpit-login {
    width: 100%;
  }

  .navbar-logo-cockpit {
    padding-left: 28px;
    padding-top: 2px;
  }

  .navbar-logo-cockpit-employee {
    padding-left: 20px;
    padding-top: 2px;
  }

  .navbar-cockpit {
    padding-left: 0px;
  }

  .navbar-cockpit-employee {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) {
  .navbar-logo-cockpit-employee {
    padding-left: 0px !important;
    padding-top: 2px;
  }
}

@media screen and (max-width: 640px) {
  .logo-font {
    font-size: 23px;
    width: 85px;
  }

  .nav-subtitle {
    font-size: 20px;
    width: 150px;
  }

  .vertical-line {
    margin: 10px;
  }

  .nav-menu-cockpit {
    display: none;
  }

  .nav-menu-cockpit-manager {
    display: none;
  }

  .navbar-cockpit {
    padding-left: 0px;
  }

  .navbar-cockpit-employee {
    padding-left: 0px;
  }
  .navbar-logo-cockpit-employee {
    padding-left: 28px !important;
    padding-top: 2px;
  }
}