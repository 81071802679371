.sidebar {
  position: fixed;
  background-color: white;
  width: 70px;
  height: 100vh;
  box-shadow: 0 1px 6px #aaa;
  clip-path: inset(0px -8px 0px 0px);
  z-index: 1050;
  top: 0;
  overflow: auto;
}

.sidebar-employee {
  display: none;
}

.sidebar-list {
  margin-top: 80px;
  padding-top: 50px;
  list-style-type: none;
  width: 100%;
}

.sidebar-icon {
  height: 32px;
  margin-left: -5px;
}

.sidebar-link {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
  padding-top: 24px;
}

.sidebar-link:hover {
  /* background-color: #7268cd17; */
}

.sidebar-item:hover {
  background-color: #7268cd17;
}

.sidebar-link-active {
  border-right: 1px solid grey;
}

.sidebar-menu-icon {
  display: none;
}

.mobile-buttons {
  display: none;
}

.ui.button.sidebar-btn, .ui.buttons.sidebar-btn .button.sidebar-btn, .ui.buttons.sidebar-btn .or.sidebar-btn {
  background-color: transparent !important;
  box-shadow: none !important;
  color: #7268cd !important;
  border: none !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  padding-bottom: 24px !important;
  padding-top: 24px !important;
}

.ui.button.sidebar-btn:hover, .ui.buttons.sidebar-btn:hover .button.sidebar-btn:hover, .ui.buttons.sidebar-btn:hover .or.sidebar-btn:hover {
  color: #7268cd !important;
}

.sidebar-menu-icon-employee {
  display: none;
}

@media screen and (max-width: 640px) {
  .mobile-buttons {
    display: initial;
  }

  .sidebar-btn {
  }

  .sidebar-employee {
    /* display: none;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 600ms, visibility 600ms;
            transition: opacity 600ms, visibility 600ms;
    animation: fadeout 1s; */
    position: fixed;
    background-color: white;
    width: 70px;
    height: 100vh;
    box-shadow: 0 1px 6px #aaa;
    clip-path: inset(0px -8px 0px 0px);
    z-index: 1050;
    top: 0;
    overflow: auto;
  }

  .sidebar-menu-icon-employee {
    display: block;
    color: #666;
    position: absolute;
    top: 32px;
    left: 18px;
    transform: translateY(-42%);
    font-size: 20px;
    cursor: pointer;
    z-index: 1090;
  }

  .sidebar-employee.active {
    display: initial;
    padding-top: 60px;
    visibility: visible;
    opacity: 1;
    animation: fade 0.5s;
    -webkit-transition: opacity 600ms, visibility 600ms;
            transition: opacity 600ms, visibility 600ms;
  }
  @keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@keyframes fadeout {
 100% {
      opacity: 0;
  }

  0% {
      opacity: 1;
  }
}
}

@media screen and (max-width: 960px) {
  .sidebar-menu-icon {
    display: block;
    color: #666;
    position: absolute;
    top: 32px;
    left: 18px;
    transform: translateY(-42%);
    font-size: 20px;
    cursor: pointer;
    z-index: 1090;
  }
  .sidebar {
    display: none;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 600ms, visibility 600ms;
            transition: opacity 600ms, visibility 600ms;
    animation: fadeout 1s;
  }

  .sidebar-employee {
    display: none;
  }

  @keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@keyframes fadeout {
 100% {
      opacity: 0;
  }

  0% {
      opacity: 1;
  }
}
  .sidebar.active {
    display: initial;
    padding-top: 60px;
    visibility: visible;
    opacity: 1;
    animation: fade 0.5s;
    -webkit-transition: opacity 600ms, visibility 600ms;
            transition: opacity 600ms, visibility 600ms;
  }
  .sidebar-list {
    display: none;
  }
  .sidebar-list.active {
    display: initial;
    top: 40px !important;
  }
}